import React, { forwardRef, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import throttle from 'lodash/throttle';
import { classNames } from 'src/utils/css';
import { CustomEase } from 'src/utils/animation/gsap-plugins/CustomEase';
import { FluidText } from 'src/components/common/FluidText';
import { wrapper_, tabSelector_, buttonBg_, track_, tabSelectorSmall_, trackSmall_, tabSelectorLarge_, trackLarge_, fullWidth_ } from './TabSelector.module.scss';
import { FormFieldLabel } from '../FormFieldLabel';

const ANIMATION_DURATION = 0.083;

const TabSelector = forwardRef(function TabSelector({ tabs = ['one', 'two', 'three', 'four'], size = 'sm', fullWidth = false, initialTab, value, onChange, className, label, message, tooltipDescription, ...rest }, ref) {
  const initialTabId = tabs.find(tab => tab === initialTab) ?? tabs[0];
  const containerRef = useRef();
  const buttonBgRef = useRef();
  const trackRef = useRef();
  const tabStatusRef = useRef({
    active: initialTabId,
    lastActive: initialTabId,
  });

  const SIZES = {
    sm: {
      fluidTextConfig: { min: 'ws-text-sm-medium', max: 'ws-text-md-medium' },
      wrapperClass: tabSelectorSmall_,
      trackClass: trackSmall_,
    },
    lg: {
      fluidTextConfig: { min: 'ws-text-sm-medium', max: 'ws-text-xl-medium' },
      wrapperClass: tabSelectorLarge_,
      trackClass: trackLarge_,
    }
  };

  const animate = () => {
    const current = containerRef.current.querySelector(
      `[data-tab-id="${tabStatusRef.current.active}"]`
    );
    const last = containerRef.current.querySelector(
      `[data-tab-id="${tabStatusRef.current.lastActive}"]`
    );

    // triggers CSS animation
    current.dataset.active = true;
    last.dataset.active = false;

    const rect = current.getBoundingClientRect();
    const elOffsetLeft = Math.round(current.offsetLeft);

    gsap.to(buttonBgRef.current, {
      duration: ANIMATION_DURATION,
      ease: 'tab-select-ease',
      x: elOffsetLeft,
    });

    // Animate button background element width to match active button dimensions.
    gsap.to(buttonBgRef.current, {
      duration: ANIMATION_DURATION,
      width: Math.round(rect.width),
      ease: 'tab-select-ease',
    });
  };

  const updateActiveTab = useCallback(tabId => {
    if (tabStatusRef.current.active !== tabId) {
      tabStatusRef.current.lastActive = tabStatusRef.current.active;
      tabStatusRef.current.active = tabId;
      if (onChange) onChange(tabId);
      animate();
    }
  }, [onChange]);

  const handleClick = e => {
    const targetEl = e.currentTarget;
    const tabId = targetEl?.dataset?.tabId;
    updateActiveTab(tabId);
  };

  useEffect(() => {
    if (!value) return;

    const tabIndex = tabs.findIndex(tab => tab === value);
    const tabId = tabs[tabIndex];
    updateActiveTab(tabId);
  }, [tabs, updateActiveTab, value]);

  useLayoutEffect(() => {
    gsap.registerPlugin(CustomEase);
    CustomEase.create('tab-select-ease', '0.33, 0.00, 0.67, 1.00');

    const handleResize = () => {
      const el = containerRef.current.querySelector(
        `[data-tab-id="${tabStatusRef.current.active}"]`
      );

      const rect = el.getBoundingClientRect();
      gsap.to(buttonBgRef.current, { duration: ANIMATION_DURATION * 4, width: rect.width, x: Math.round(el.offsetLeft), ease: 'tab-select-ease' });
    };

    gsap.delayedCall(0.3, handleResize);
    const throttledResize = throttle(handleResize, 200);
    window.addEventListener('resize', throttledResize);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, []);

  return (
    <div ref={ref} className={wrapper_}>
      {label && (
        <FormFieldLabel label={label} message={message} tooltipDescription={tooltipDescription} />
      )}
      <div ref={containerRef} className={classNames(tabSelector_, SIZES[size].wrapperClass, fullWidth && fullWidth_, className)} {...rest}>
        <div ref={trackRef} className={classNames(track_, SIZES[size].trackClass)}>
          <div ref={buttonBgRef} className={buttonBg_} />
          {tabs.map(tabName => {
            return (
              <button key={tabName} onClick={handleClick} data-tab-id={tabName} data-active={tabName === tabStatusRef.current.active} type="button">
                <FluidText type="span" {...SIZES[size].fluidTextConfig}>{tabName}</FluidText>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string.isRequired),
  initialTab: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  message: PropTypes.string,
};

export { TabSelector };
