import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { asSubsection } from 'src/components/layout';
import { FluidText, DEFAULT_CONFIG_BY_ELEMENT_TYPE } from 'src/components/common/FluidText';
import { classNames } from 'src/utils/css';
import {
  closingCtaBlock,
  hasBackgroundImage_,
  headingDisplay,
  subheadingDisplay,
  ctaDisplay,
  fullscreen_,
} from './CallToActionBlock.module.scss';

const CallToActionBlock = asSubsection('CallToActionBlock')(
  ({ heading, subheading, cta, fullscreen = true, hasBackgroundImage = false, className, ...rest }) => {
    const headingClasses = classNames(headingDisplay);
    const subHeadingClasses = classNames(subheadingDisplay);

    const headingComponent = isString(heading) ? (
      <FluidText type="h2" className={headingClasses} {...DEFAULT_CONFIG_BY_ELEMENT_TYPE.h1}>
        {heading}
      </FluidText>
    ) : (
      heading &&
      React.cloneElement(heading, {
        className: classNames(headingClasses, heading?.props?.className),
      })
    );

    const subheadingComponent = isString(subheading) ? (
      <FluidText type="p" className={subHeadingClasses}>
        {subheading}
      </FluidText>
    ) : (
      subheading &&
      React.cloneElement(subheading, {
        className: classNames(subHeadingClasses, subheading?.props?.className),
      })
    );

    return (
      <div className={classNames(closingCtaBlock, fullscreen && fullscreen_, hasBackgroundImage && hasBackgroundImage_, className)} {...rest}>
        {headingComponent}
        {subheadingComponent}
        {cta && <div className={ctaDisplay}>{cta}</div>}
      </div>
    );
  }
);

CallToActionBlock.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cta: PropTypes.node,
  fullscreen: PropTypes.bool,
};

CallToActionBlock.displayName = 'CallToActionBlock';

export { CallToActionBlock };
