let _svgPathExp = /[achlmqstvz]|(-?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi,
  _numbersExp = /(?:(-)?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi,
  _scientific = /[\+\-]?\d*\.?\d+e[\+\-]?\d+/gi,
  _selectorExp = /(^[#\.][a-z]|[a-y][a-z])/i,
  _DEG2RAD = Math.PI / 180,
  _RAD2DEG = 180 / Math.PI,
  _sin = Math.sin,
  _cos = Math.cos,
  _abs = Math.abs,
  _sqrt = Math.sqrt,
  _atan2 = Math.atan2,
  _largeNum = 1e8,
  _isString = value => typeof value === 'string',
  _isNumber = value => typeof value === 'number',
  _isUndefined = value => typeof value === 'undefined',
  _temp = {},
  _temp2 = {},
  _roundingNum = 1e5,
  _wrapProgress = progress =>
    Math.round(((progress + _largeNum) % 1) * _roundingNum) / _roundingNum ||
    (progress < 0 ? 0 : 1), //if progress lands on 1, the % will make it 0 which is why we || 1, but not if it's negative because it makes more sense for motion to end at 0 in that case.
  _round = value => ~~(value * _roundingNum + (value < 0 ? -0.5 : 0.5)) / _roundingNum,
  _splitSegment = (rawPath, segIndex, i, t) => {
    let segment = rawPath[segIndex],
      shift = t === 1 ? 6 : subdivideSegment(segment, i, t);
    if (shift && shift + i + 2 < segment.length) {
      rawPath.splice(segIndex, 0, segment.slice(0, i + shift + 2));
      segment.splice(0, i + shift);
      return 1;
    }
  },
  _reverseRawPath = (rawPath, skipOuter) => {
    let i = rawPath.length;
    if (!skipOuter) {
      rawPath.reverse();
    }
    while (i--) {
      if (!rawPath[i].reversed) {
        reverseSegment(rawPath[i]);
      }
    }
  },
  _copyMetaData = (source, copy) => {
    copy.totalLength = source.totalLength;
    if (source.samples) {
      //segment
      copy.samples = source.samples.slice(0);
      copy.lookup = source.lookup.slice(0);
      copy.minLength = source.minLength;
      copy.resolution = source.resolution;
    } else {
      //rawPath
      copy.totalPoints = source.totalPoints;
    }
    return copy;
  },
  //pushes a new segment into a rawPath, but if its starting values match the ending values of the last segment, it'll merge it into that same segment (to reduce the number of segments)
  _appendOrMerge = (rawPath, segment) => {
    let index = rawPath.length,
      prevSeg = rawPath[index - 1] || [],
      l = prevSeg.length;
    if (segment[0] === prevSeg[l - 2] && segment[1] === prevSeg[l - 1]) {
      segment = prevSeg.concat(segment.slice(2));
      index--;
    }
    rawPath[index] = segment;
  },
  _bestDistance;

/* TERMINOLOGY
 - RawPath - an array of arrays, one for each Segment. A single RawPath could have multiple "M" commands, defining Segments (paths aren't always connected).
 - Segment - an array containing a sequence of Cubic Bezier coordinates in alternating x, y, x, y format. Starting anchor, then control point 1, control point 2, and ending anchor, then the next control point 1, control point 2, anchor, etc. Uses less memory than an array with a bunch of {x, y} points.
 - Bezier - a single cubic Bezier with a starting anchor, two control points, and an ending anchor.
 - the variable "t" is typically the position along an individual Bezier path (time) and it's NOT linear, meaning it could accelerate/decelerate based on the control points whereas the "p" or "progress" value is linearly mapped to the whole path, so it shouldn't really accelerate/decelerate based on control points. So a progress of 0.2 would be almost exactly 20% along the path. "t" is ONLY in an individual Bezier piece.
 */

//accepts basic selector text, a path instance, a RawPath instance, or a Segment and returns a RawPath (makes it easy to homogenize things). If an element or selector text is passed in, it'll also cache the value so that if it's queried again, it'll just take the path data from there instead of parsing it all over again (as long as the path data itself hasn't changed - it'll check).
export function getRawPath(value) {
  value =
    _isString(value) && _selectorExp.test(value) ? document.querySelector(value) || value : value;
  let e = value.getAttribute ? value : 0,
    rawPath;
  if (e && (value = value.getAttribute('d'))) {
    //implements caching
    if (!e._gsPath) {
      e._gsPath = {};
    }
    rawPath = e._gsPath[value];
    return rawPath && !rawPath._dirty ? rawPath : (e._gsPath[value] = stringToRawPath(value));
  }
  return !value
    ? console.warn('Expecting a <path> element or an SVG path data string')
    : _isString(value)
    ? stringToRawPath(value)
    : _isNumber(value[0])
    ? [value]
    : value;
}

//copies a RawPath WITHOUT the length meta data (for speed)
export function copyRawPath(rawPath) {
  let a = [],
    i = 0;
  for (; i < rawPath.length; i++) {
    a[i] = _copyMetaData(rawPath[i], rawPath[i].slice(0));
  }
  return _copyMetaData(rawPath, a);
}

export function reverseSegment(segment) {
  let i = 0,
    y;
  segment.reverse(); //this will invert the order y, x, y, x so we must flip it back.
  for (; i < segment.length; i += 2) {
    y = segment[i];
    segment[i] = segment[i + 1];
    segment[i + 1] = y;
  }
  segment.reversed = !segment.reversed;
}

let _createPath = (e, ignore) => {
    let path = document.createElementNS('http://www.w3.org/2000/svg', 'path'),
      attr = [].slice.call(e.attributes),
      i = attr.length,
      name;
    ignore = ',' + ignore + ',';
    while (--i > -1) {
      name = attr[i].nodeName.toLowerCase(); //in Microsoft Edge, if you don't set the attribute with a lowercase name, it doesn't render correctly! Super weird.
      if (ignore.indexOf(',' + name + ',') < 0) {
        path.setAttributeNS(null, name, attr[i].nodeValue);
      }
    }
    return path;
  },
  _typeAttrs = {
    rect: 'rx,ry,x,y,width,height',
    circle: 'r,cx,cy',
    ellipse: 'rx,ry,cx,cy',
    line: 'x1,x2,y1,y2',
  },
  _attrToObj = (e, attrs) => {
    let props = attrs ? attrs.split(',') : [],
      obj = {},
      i = props.length;
    while (--i > -1) {
      obj[props[i]] = +e.getAttribute(props[i]) || 0;
    }
    return obj;
  };

//converts an SVG shape like <circle>, <rect>, <polygon>, <polyline>, <ellipse>, etc. to a <path>, swapping it in and copying the attributes to match.
export function convertToPath(element, swap) {
  let type = element.tagName.toLowerCase(),
    circ = 0.552284749831,
    data,
    x,
    y,
    r,
    ry,
    path,
    rcirc,
    rycirc,
    points,
    w,
    h,
    x2,
    x3,
    x4,
    x5,
    x6,
    y2,
    y3,
    y4,
    y5,
    y6,
    attr;
  if (type === 'path' || !element.getBBox) {
    return element;
  }
  path = _createPath(element, 'x,y,width,height,cx,cy,rx,ry,r,x1,x2,y1,y2,points');
  attr = _attrToObj(element, _typeAttrs[type]);
  if (type === 'rect') {
    r = attr.rx;
    ry = attr.ry;
    x = attr.x;
    y = attr.y;
    w = attr.width - r * 2;
    h = attr.height - ry * 2;
    if (r || ry) {
      //if there are rounded corners, render cubic beziers
      x2 = x + r * (1 - circ);
      x3 = x + r;
      x4 = x3 + w;
      x5 = x4 + r * circ;
      x6 = x4 + r;
      y2 = y + ry * (1 - circ);
      y3 = y + ry;
      y4 = y3 + h;
      y5 = y4 + ry * circ;
      y6 = y4 + ry;
      data =
        'M' +
        x6 +
        ',' +
        y3 +
        ' V' +
        y4 +
        ' C' +
        [
          x6,
          y5,
          x5,
          y6,
          x4,
          y6,
          x4 - (x4 - x3) / 3,
          y6,
          x3 + (x4 - x3) / 3,
          y6,
          x3,
          y6,
          x2,
          y6,
          x,
          y5,
          x,
          y4,
          x,
          y4 - (y4 - y3) / 3,
          x,
          y3 + (y4 - y3) / 3,
          x,
          y3,
          x,
          y2,
          x2,
          y,
          x3,
          y,
          x3 + (x4 - x3) / 3,
          y,
          x4 - (x4 - x3) / 3,
          y,
          x4,
          y,
          x5,
          y,
          x6,
          y2,
          x6,
          y3,
        ].join(',') +
        'z';
    } else {
      data = 'M' + (x + w) + ',' + y + ' v' + h + ' h' + -w + ' v' + -h + ' h' + w + 'z';
    }
  } else if (type === 'circle' || type === 'ellipse') {
    if (type === 'circle') {
      r = ry = attr.r;
      rycirc = r * circ;
    } else {
      r = attr.rx;
      ry = attr.ry;
      rycirc = ry * circ;
    }
    x = attr.cx;
    y = attr.cy;
    rcirc = r * circ;
    data =
      'M' +
      (x + r) +
      ',' +
      y +
      ' C' +
      [
        x + r,
        y + rycirc,
        x + rcirc,
        y + ry,
        x,
        y + ry,
        x - rcirc,
        y + ry,
        x - r,
        y + rycirc,
        x - r,
        y,
        x - r,
        y - rycirc,
        x - rcirc,
        y - ry,
        x,
        y - ry,
        x + rcirc,
        y - ry,
        x + r,
        y - rycirc,
        x + r,
        y,
      ].join(',') +
      'z';
  } else if (type === 'line') {
    data = 'M' + attr.x1 + ',' + attr.y1 + ' L' + attr.x2 + ',' + attr.y2; //previously, we just converted to "Mx,y Lx,y" but Safari has bugs that cause that not to render properly when using a stroke-dasharray that's not fully visible! Using a cubic bezier fixes that issue.
  } else if (type === 'polyline' || type === 'polygon') {
    points = (element.getAttribute('points') + '').match(_numbersExp) || [];
    x = points.shift();
    y = points.shift();
    data = 'M' + x + ',' + y + ' L' + points.join(',');
    if (type === 'polygon') {
      data += ',' + x + ',' + y + 'z';
    }
  }
  path.setAttribute('d', rawPathToString((path._gsRawPath = stringToRawPath(data))));
  if (swap && element.parentNode) {
    element.parentNode.insertBefore(path, element);
    element.parentNode.removeChild(element);
  }
  return path;
}

//measures a Segment according to its resolution (so if segment.resolution is 6, for example, it'll take 6 samples equally across each Bezier) and create/populate a "samples" array that has the length up to each of those sample points (always increasing from the start) as well as a "lookup" array that's broken up according to the smallest distance between 2 samples. This gives us a very fast way of looking up a progress position rather than looping through all the points/Beziers. You can optionally have it only measure a subset, starting at startIndex and going for a specific number of beziers (remember, there are 3 x/y pairs each, for a total of 6 elements for each Bezier). It will also populate a "totalLength" property, but that's not generally super accurate because by default it'll only take 6 samples per Bezier. But for performance reasons, it's perfectly adequate for measuring progress values along the path. If you need a more accurate totalLength, either increase the resolution or use the more advanced bezierToPoints() method which keeps adding points until they don't deviate by more than a certain precision value.
function measureSegment(segment, startIndex, bezierQty) {
  startIndex = startIndex || 0;
  if (!segment.samples) {
    segment.samples = [];
    segment.lookup = [];
  }
  let resolution = ~~segment.resolution || 12,
    inc = 1 / resolution,
    endIndex = bezierQty ? startIndex + bezierQty * 6 + 1 : segment.length,
    x1 = segment[startIndex],
    y1 = segment[startIndex + 1],
    samplesIndex = startIndex ? (startIndex / 6) * resolution : 0,
    samples = segment.samples,
    lookup = segment.lookup,
    min = (startIndex ? segment.minLength : _largeNum) || _largeNum,
    prevLength = samples[samplesIndex + bezierQty * resolution - 1],
    length = startIndex ? samples[samplesIndex - 1] : 0,
    i,
    j,
    x4,
    x3,
    x2,
    xd,
    xd1,
    y4,
    y3,
    y2,
    yd,
    yd1,
    inv,
    t,
    lengthIndex,
    l,
    segLength;
  samples.length = lookup.length = 0;
  for (j = startIndex + 2; j < endIndex; j += 6) {
    x4 = segment[j + 4] - x1;
    x3 = segment[j + 2] - x1;
    x2 = segment[j] - x1;
    y4 = segment[j + 5] - y1;
    y3 = segment[j + 3] - y1;
    y2 = segment[j + 1] - y1;
    xd = xd1 = yd = yd1 = 0;
    if (_abs(x4) < 1e-5 && _abs(y4) < 1e-5 && _abs(x2) + _abs(y2) < 1e-5) {
      //dump points that are sufficiently close (basically right on top of each other, making a bezier super tiny or 0 length)
      if (segment.length > 8) {
        segment.splice(j, 6);
        j -= 6;
        endIndex -= 6;
      }
    } else {
      for (i = 1; i <= resolution; i++) {
        t = inc * i;
        inv = 1 - t;
        xd = xd1 - (xd1 = (t * t * x4 + 3 * inv * (t * x3 + inv * x2)) * t);
        yd = yd1 - (yd1 = (t * t * y4 + 3 * inv * (t * y3 + inv * y2)) * t);
        l = _sqrt(yd * yd + xd * xd);
        if (l < min) {
          min = l;
        }
        length += l;
        samples[samplesIndex++] = length;
      }
    }
    x1 += x4;
    y1 += y4;
  }
  if (prevLength) {
    prevLength -= length;
    for (; samplesIndex < samples.length; samplesIndex++) {
      samples[samplesIndex] += prevLength;
    }
  }
  if (samples.length && min) {
    segment.totalLength = segLength = samples[samples.length - 1] || 0;
    segment.minLength = min;
    l = lengthIndex = 0;
    for (i = 0; i < segLength; i += min) {
      lookup[l++] = samples[lengthIndex] < i ? ++lengthIndex : lengthIndex;
    }
  } else {
    segment.totalLength = samples[0] = 0;
  }
  return startIndex ? length - samples[startIndex / 2 - 1] : length;
}

export function cacheRawPathMeasurements(rawPath, resolution) {
  let pathLength, points, i;
  for (i = pathLength = points = 0; i < rawPath.length; i++) {
    rawPath[i].resolution = ~~resolution || 12; //steps per Bezier curve (anchor, 2 control points, to anchor)
    points += rawPath[i].length;
    pathLength += measureSegment(rawPath[i]);
  }
  rawPath.totalPoints = points;
  rawPath.totalLength = pathLength;
  return rawPath;
}

//divide segment[i] at position t (value between 0 and 1, progress along that particular cubic bezier segment that starts at segment[i]). Returns how many elements were spliced into the segment array (either 0 or 6)
export function subdivideSegment(segment, i, t) {
  if (t <= 0 || t >= 1) {
    return 0;
  }
  let ax = segment[i],
    ay = segment[i + 1],
    cp1x = segment[i + 2],
    cp1y = segment[i + 3],
    cp2x = segment[i + 4],
    cp2y = segment[i + 5],
    bx = segment[i + 6],
    by = segment[i + 7],
    x1a = ax + (cp1x - ax) * t,
    x2 = cp1x + (cp2x - cp1x) * t,
    y1a = ay + (cp1y - ay) * t,
    y2 = cp1y + (cp2y - cp1y) * t,
    x1 = x1a + (x2 - x1a) * t,
    y1 = y1a + (y2 - y1a) * t,
    x2a = cp2x + (bx - cp2x) * t,
    y2a = cp2y + (by - cp2y) * t;
  x2 += (x2a - x2) * t;
  y2 += (y2a - y2) * t;
  segment.splice(
    i + 2,
    4,
    _round(x1a), //first control point
    _round(y1a),
    _round(x1), //second control point
    _round(y1),
    _round(x1 + (x2 - x1) * t), //new fabricated anchor on line
    _round(y1 + (y2 - y1) * t),
    _round(x2), //third control point
    _round(y2),
    _round(x2a), //fourth control point
    _round(y2a)
  );
  if (segment.samples) {
    segment.samples.splice(((i / 6) * segment.resolution) | 0, 0, 0, 0, 0, 0, 0, 0);
  }
  return 6;
}

// applies a matrix transform to RawPath (or a segment in a RawPath) and returns whatever was passed in (it transforms the values in the array(s), not a copy).
export function transformRawPath(rawPath, a, b, c, d, tx, ty) {
  let j = rawPath.length,
    segment,
    l,
    i,
    x,
    y;
  while (--j > -1) {
    segment = rawPath[j];
    l = segment.length;
    for (i = 0; i < l; i += 2) {
      x = segment[i];
      y = segment[i + 1];
      segment[i] = x * a + y * c + tx;
      segment[i + 1] = x * b + y * d + ty;
    }
  }
  rawPath._dirty = 1;
  return rawPath;
}

// translates SVG arc data into a segment (cubic beziers). Angle is in degrees.
function arcToSegment(lastX, lastY, rx, ry, angle, largeArcFlag, sweepFlag, x, y) {
  if (lastX === x && lastY === y) {
    return;
  }
  rx = _abs(rx);
  ry = _abs(ry);
  let angleRad = (angle % 360) * _DEG2RAD,
    cosAngle = _cos(angleRad),
    sinAngle = _sin(angleRad),
    PI = Math.PI,
    TWOPI = PI * 2,
    dx2 = (lastX - x) / 2,
    dy2 = (lastY - y) / 2,
    x1 = cosAngle * dx2 + sinAngle * dy2,
    y1 = -sinAngle * dx2 + cosAngle * dy2,
    x1_sq = x1 * x1,
    y1_sq = y1 * y1,
    radiiCheck = x1_sq / (rx * rx) + y1_sq / (ry * ry);
  if (radiiCheck > 1) {
    rx = _sqrt(radiiCheck) * rx;
    ry = _sqrt(radiiCheck) * ry;
  }
  let rx_sq = rx * rx,
    ry_sq = ry * ry,
    sq = (rx_sq * ry_sq - rx_sq * y1_sq - ry_sq * x1_sq) / (rx_sq * y1_sq + ry_sq * x1_sq);
  if (sq < 0) {
    sq = 0;
  }
  let coef = (largeArcFlag === sweepFlag ? -1 : 1) * _sqrt(sq),
    cx1 = coef * ((rx * y1) / ry),
    cy1 = coef * -((ry * x1) / rx),
    sx2 = (lastX + x) / 2,
    sy2 = (lastY + y) / 2,
    cx = sx2 + (cosAngle * cx1 - sinAngle * cy1),
    cy = sy2 + (sinAngle * cx1 + cosAngle * cy1),
    ux = (x1 - cx1) / rx,
    uy = (y1 - cy1) / ry,
    vx = (-x1 - cx1) / rx,
    vy = (-y1 - cy1) / ry,
    temp = ux * ux + uy * uy,
    angleStart = (uy < 0 ? -1 : 1) * Math.acos(ux / _sqrt(temp)),
    angleExtent =
      (ux * vy - uy * vx < 0 ? -1 : 1) *
      Math.acos((ux * vx + uy * vy) / _sqrt(temp * (vx * vx + vy * vy)));
  if (isNaN(angleExtent)) {
    //rare edge case. Math.cos(-1) is NaN.
    angleExtent = PI;
  }
  if (!sweepFlag && angleExtent > 0) {
    angleExtent -= TWOPI;
  } else if (sweepFlag && angleExtent < 0) {
    angleExtent += TWOPI;
  }
  angleStart %= TWOPI;
  angleExtent %= TWOPI;
  let segments = Math.ceil(_abs(angleExtent) / (TWOPI / 4)),
    rawPath = [],
    angleIncrement = angleExtent / segments,
    controlLength = ((4 / 3) * _sin(angleIncrement / 2)) / (1 + _cos(angleIncrement / 2)),
    ma = cosAngle * rx,
    mb = sinAngle * rx,
    mc = sinAngle * -ry,
    md = cosAngle * ry,
    i;
  for (i = 0; i < segments; i++) {
    angle = angleStart + i * angleIncrement;
    x1 = _cos(angle);
    y1 = _sin(angle);
    ux = _cos((angle += angleIncrement));
    uy = _sin(angle);
    rawPath.push(
      x1 - controlLength * y1,
      y1 + controlLength * x1,
      ux + controlLength * uy,
      uy - controlLength * ux,
      ux,
      uy
    );
  }
  //now transform according to the actual size of the ellipse/arc (the beziers were noramlized, between 0 and 1 on a circle).
  for (i = 0; i < rawPath.length; i += 2) {
    x1 = rawPath[i];
    y1 = rawPath[i + 1];
    rawPath[i] = x1 * ma + y1 * mc + cx;
    rawPath[i + 1] = x1 * mb + y1 * md + cy;
  }
  rawPath[i - 2] = x; //always set the end to exactly where it's supposed to be
  rawPath[i - 1] = y;
  return rawPath;
}

//Spits back a RawPath with absolute coordinates. Each segment starts with a "moveTo" command (x coordinate, then y) and then 2 control points (x, y, x, y), then anchor. The goal is to minimize memory and maximize speed.
export function stringToRawPath(d) {
  let a =
      (d + '')
        .replace(_scientific, m => {
          let n = +m;
          return n < 0.0001 && n > -0.0001 ? 0 : n;
        })
        .match(_svgPathExp) || [], //some authoring programs spit out very small numbers in scientific notation like "1e-5", so make sure we round that down to 0 first.
    path = [],
    relativeX = 0,
    relativeY = 0,
    twoThirds = 2 / 3,
    elements = a.length,
    points = 0,
    errorMessage = 'ERROR: malformed path: ' + d,
    i,
    j,
    x,
    y,
    command,
    isRelative,
    segment,
    startX,
    startY,
    difX,
    difY,
    beziers,
    prevCommand,
    flag1,
    flag2,
    line = function(sx, sy, ex, ey) {
      difX = (ex - sx) / 3;
      difY = (ey - sy) / 3;
      segment.push(sx + difX, sy + difY, ex - difX, ey - difY, ex, ey);
    };
  if (!d || !isNaN(a[0]) || isNaN(a[1])) {
    console.log(errorMessage);
    return path;
  }
  for (i = 0; i < elements; i++) {
    prevCommand = command;
    if (isNaN(a[i])) {
      command = a[i].toUpperCase();
      isRelative = command !== a[i]; //lower case means relative
    } else {
      //commands like "C" can be strung together without any new command characters between.
      i--;
    }
    x = +a[i + 1];
    y = +a[i + 2];
    if (isRelative) {
      x += relativeX;
      y += relativeY;
    }
    if (!i) {
      startX = x;
      startY = y;
    }

    // "M" (move)
    if (command === 'M') {
      if (segment) {
        if (segment.length < 8) {
          //if the path data was funky and just had a M with no actual drawing anywhere, skip it.
          path.length -= 1;
        } else {
          points += segment.length;
        }
      }
      relativeX = startX = x;
      relativeY = startY = y;
      segment = [x, y];
      path.push(segment);
      i += 2;
      command = 'L'; //an "M" with more than 2 values gets interpreted as "lineTo" commands ("L").

      // "C" (cubic bezier)
    } else if (command === 'C') {
      if (!segment) {
        segment = [0, 0];
      }
      if (!isRelative) {
        relativeX = relativeY = 0;
      }
      //note: "*1" is just a fast/short way to cast the value as a Number. WAAAY faster in Chrome, slightly slower in Firefox.
      segment.push(
        x,
        y,
        relativeX + a[i + 3] * 1,
        relativeY + a[i + 4] * 1,
        (relativeX += a[i + 5] * 1),
        (relativeY += a[i + 6] * 1)
      );
      i += 6;

      // "S" (continuation of cubic bezier)
    } else if (command === 'S') {
      difX = relativeX;
      difY = relativeY;
      if (prevCommand === 'C' || prevCommand === 'S') {
        difX += relativeX - segment[segment.length - 4];
        difY += relativeY - segment[segment.length - 3];
      }
      if (!isRelative) {
        relativeX = relativeY = 0;
      }
      segment.push(difX, difY, x, y, (relativeX += a[i + 3] * 1), (relativeY += a[i + 4] * 1));
      i += 4;

      // "Q" (quadratic bezier)
    } else if (command === 'Q') {
      difX = relativeX + (x - relativeX) * twoThirds;
      difY = relativeY + (y - relativeY) * twoThirds;
      if (!isRelative) {
        relativeX = relativeY = 0;
      }
      relativeX += a[i + 3] * 1;
      relativeY += a[i + 4] * 1;
      segment.push(
        difX,
        difY,
        relativeX + (x - relativeX) * twoThirds,
        relativeY + (y - relativeY) * twoThirds,
        relativeX,
        relativeY
      );
      i += 4;

      // "T" (continuation of quadratic bezier)
    } else if (command === 'T') {
      difX = relativeX - segment[segment.length - 4];
      difY = relativeY - segment[segment.length - 3];
      segment.push(
        relativeX + difX,
        relativeY + difY,
        x + (relativeX + difX * 1.5 - x) * twoThirds,
        y + (relativeY + difY * 1.5 - y) * twoThirds,
        (relativeX = x),
        (relativeY = y)
      );
      i += 2;

      // "H" (horizontal line)
    } else if (command === 'H') {
      line(relativeX, relativeY, (relativeX = x), relativeY);
      i += 1;

      // "V" (vertical line)
    } else if (command === 'V') {
      //adjust values because the first (and only one) isn't x in this case, it's y.
      line(
        relativeX,
        relativeY,
        relativeX,
        (relativeY = x + (isRelative ? relativeY - relativeX : 0))
      );
      i += 1;

      // "L" (line) or "Z" (close)
    } else if (command === 'L' || command === 'Z') {
      if (command === 'Z') {
        x = startX;
        y = startY;
        segment.closed = true;
      }
      if (command === 'L' || _abs(relativeX - x) > 0.5 || _abs(relativeY - y) > 0.5) {
        line(relativeX, relativeY, x, y);
        if (command === 'L') {
          i += 2;
        }
      }
      relativeX = x;
      relativeY = y;

      // "A" (arc)
    } else if (command === 'A') {
      flag1 = a[i + 4];
      flag2 = a[i + 5];
      difX = a[i + 6];
      difY = a[i + 7];
      j = 7;
      if (flag1.length > 1) {
        // for cases when the flags are merged, like "a8 8 0 018 8" (the 0 and 1 flags are WITH the x value of 8, but it could also be "a8 8 0 01-8 8" so it may include x or not)
        if (flag1.length < 3) {
          difY = difX;
          difX = flag2;
          j--;
        } else {
          difY = flag2;
          difX = flag1.substr(2);
          j -= 2;
        }
        flag2 = flag1.charAt(1);
        flag1 = flag1.charAt(0);
      }
      beziers = arcToSegment(
        relativeX,
        relativeY,
        +a[i + 1],
        +a[i + 2],
        +a[i + 3],
        +flag1,
        +flag2,
        (isRelative ? relativeX : 0) + difX * 1,
        (isRelative ? relativeY : 0) + difY * 1
      );
      i += j;
      if (beziers) {
        for (j = 0; j < beziers.length; j++) {
          segment.push(beziers[j]);
        }
      }
      relativeX = segment[segment.length - 2];
      relativeY = segment[segment.length - 1];
    } else {
      console.log(errorMessage);
    }
  }
  i = segment.length;
  if (i < 6) {
    //in case there's odd SVG like a M0,0 command at the very end.
    path.pop();
    i = 0;
  } else if (segment[0] === segment[i - 2] && segment[1] === segment[i - 1]) {
    segment.closed = true;
  }
  path.totalPoints = points + i;
  return path;
}

/*
Takes any of the following and converts it to an all Cubic Bezier SVG data string:
- A <path> data string like "M0,0 L2,4 v20,15 H100"
- A RawPath, like [[x, y, x, y, x, y, x, y][[x, y, x, y, x, y, x, y]]
- A Segment, like [x, y, x, y, x, y, x, y]

Note: all numbers are rounded down to the closest 0.001 to minimize memory, maximize speed, and avoid odd numbers like 1e-13
*/
export function rawPathToString(rawPath) {
  if (_isNumber(rawPath[0])) {
    //in case a segment is passed in instead
    rawPath = [rawPath];
  }
  let result = '',
    l = rawPath.length,
    sl,
    s,
    i,
    segment;
  for (s = 0; s < l; s++) {
    segment = rawPath[s];
    result += 'M' + _round(segment[0]) + ',' + _round(segment[1]) + ' C';
    sl = segment.length;
    for (i = 2; i < sl; i++) {
      result +=
        _round(segment[i++]) +
        ',' +
        _round(segment[i++]) +
        ' ' +
        _round(segment[i++]) +
        ',' +
        _round(segment[i++]) +
        ' ' +
        _round(segment[i++]) +
        ',' +
        _round(segment[i]) +
        ' ';
    }
    if (segment.closed) {
      result += 'z';
    }
  }
  return result;
}

/* eslint-enable */
